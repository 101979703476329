/**
 * @scada/api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.21.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EnergyManagementChartResponseChartInner } from './energyManagementChartResponseChartInner';
import { EnergyChartItemResponse } from './energyChartItemResponse';


export interface EnergyManagementChartResponse { 
    chart: Array<EnergyManagementChartResponseChartInner>;
    data?: EnergyChartItemResponse;
}

