/**
 * @scada/api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.21.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EnergyChartItemResponse { 
    installedCapacity?: number;
    radiationIntensity?: number;
    theoryPower?: number;
    performanceRatio?: number;
    inverterPower?: number;
    onGridPower?: number;
    usePower?: number;
    powerProfit?: number;
    perpowerRatio?: number;
    reductionTotalCo2?: number;
    reductionTotalCoal?: number;
    reductionTotalTree?: number;
    buyPower?: number;
    chargeCap?: number;
    dischargeCap?: number;
    selfUsePower?: number;
    selfProvide?: number;
    PVYield?: number;
    inverterYield?: number;
}

