/**
 * @scada/api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.21.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RealTimeStatsResponseTotalInvertersInner } from './realTimeStatsResponseTotalInvertersInner';


export interface RealTimeStatsResponse { 
    totalIncome: number;
    totalPower: number;
    dayPower: number;
    dayIncome: number;
    currentPower: number;
    totalInstalledCapacity: number;
    totalInverters: Array<RealTimeStatsResponseTotalInvertersInner>;
}

