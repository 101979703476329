/**
 * @scada/api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.21.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PlantItemResponse { 
    capacity: number;
    contactMethod?: string;
    contactPerson?: string;
    gridConnectionDate: string;
    latitude: string;
    longitude: string;
    plantAddress: string;
    plantCode: string;
    plantName: string;
    plantTimeZone?: string;
    dateInPlantTimeZone?: string;
    timezoneOffset?: string;
    realHealthState?: number;
    realHealthStateDescription?: string;
    inverterType?: string;
    manufacturer?: string;
}

